import AboutMe from "../../LandingPage/AboutMe/AboutMe";
import "./ContactDetails.css";

const ContactDetails = () => {
  return (
    <div className="Contact-details-wrapper">
      <AboutMe />
    </div>
  );
};

export default ContactDetails;
