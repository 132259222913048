import { Outlet } from "react-router-dom";

const Resume = () => {
  return (
    <>
      <div>resume</div>
      <Outlet />
    </>
  );
};

export default Resume;
